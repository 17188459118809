import { UrlService } from './UrlService';
import { HrefLangsMappingNonUs } from '../../i18n';
import { socialMeta } from '../atoms/Meta/Meta';
import { noIndexPages } from '../constants/NoIndexPages';
import { IGame } from '../models/Game/Game';
import { LocalesPath } from '../models/LocalesPath';
import { GeneralPageSEO } from '../models/PagesData';

type MetaInfo = {
  ogTitle: string;
  ogDescription: string;
  twitterTitle: string;
  twitterDescription: string;
  ogImage: string;
  ogUrl: string;
  twitterImage: string;
};

type PageMetaInfo = {
  content: string;
  property?: string;
  name?: string;
};

type HrefLangAddon = {
  rel: 'alternate';
  hreflang: string;
  href: string;
};

export class MetaInfoService {
  static getLinksInfo = (enPathname: string, localesPath?: LocalesPath[]): HrefLangAddon[] => {
    const path = UrlService.cleanupPathname(enPathname);
    const hrefLangAddon: HrefLangAddon[] = [];

    if (localesPath?.length > 0) {
      localesPath.forEach((item) => {
        if (
          !(
            noIndexPages.includes(item.lang + item.path) ||
            noIndexPages.includes(item.lang + item.path.replace(/\/+$/, ''))
          )
        ) {
          hrefLangAddon.push({
            rel: 'alternate',
            hreflang: HrefLangsMappingNonUs[item.lang],
            href: UrlService.createURL(`/${item.lang}${item.path}`, true, false),
          });
        }
      });
    }

    return [
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: UrlService.createURL(path, true, false),
      },
      {
        rel: 'alternate',
        hreflang: 'en-us',
        href: UrlService.createURL(path, true, false),
      },
      ...hrefLangAddon,
    ];
  };

  static getPageMetaInfo = (pageSeo: GeneralPageSEO, lang: string, url: string) => {
    const imageUrl = UrlService.toCDNUrl('https://www.arkadium.com/images/logo_medium_sq_355.png');
    let noIndex = false;

    if (url) {
      noIndexPages.forEach((i) => {
        if (url.includes(i)) {
          noIndex = true;
        }
      });
    }

    const info = {
      ogTitle: pageSeo.metaHTMLTitle[lang],
      ogDescription: pageSeo.metaHTMLDescription[lang],
      twitterTitle: pageSeo.metaHTMLTitle[lang],
      twitterDescription: pageSeo.metaHTMLDescription[lang],
      ogImage: UrlService.updateImageHostToFacebookValid(imageUrl),
      ogUrl: url,
      twitterImage: imageUrl,
    };

    return MetaInfoService.getMetaInfo(info, noIndex);
  };

  static getGameMetaInfo = (game: IGame, urlPath?: string) => {
    const info = {
      ogTitle: game.metaHTMLTitle,
      ogDescription: game.metaHTMLDescription,
      twitterTitle: game.metaHTMLTitle,
      twitterDescription: game.metaHTMLDescription,
      ogImage: UrlService.updateImageHostToFacebookValid(game.promoTile1000x312),
      ogUrl: urlPath,
      twitterImage: game.promoTile1000x312,
    };

    return MetaInfoService.getMetaInfo(info);
  };

  private static getMetaInfo = (info: MetaInfo, noIndex?: boolean): PageMetaInfo[] => [
    {
      property: 'og:title',
      content: info.ogTitle,
    },
    {
      property: 'og:description',
      content: info.ogDescription,
    },
    {
      name: 'twitter:description',
      content: info.twitterDescription,
    },
    {
      name: 'twitter:title',
      content: info.twitterTitle,
    },
    {
      property: 'og:image',
      content: info.ogImage,
    },
    {
      property: 'og:url',
      content: info.ogUrl,
    },
    {
      name: 'twitter:image',
      content: info.twitterImage,
    },
    {
      name: 'robots',
      content: noIndex ? 'noindex, nofollow' : 'max-snippet:-1, max-image-preview:large, max-video-preview:-1',
    },
    ...socialMeta,
  ];
}
