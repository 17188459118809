// TODO: what we do here?
export const socialMeta = [
  { property: 'og:locale', content: 'en_US' },
  { property: 'og:type', content: 'website' },
  { property: 'og:site_name', content: 'Arkadium' },
  {
    property: 'article:publisher',
    content: 'https://www.facebook.com/arkadium',
  },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:site', content: '@Arkadium' },
  { name: 'twitter:creator', content: '@Arkadium' },
];
